import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Country } from "country-state-city";
import NaijaStates from "naija-state-local-government";
import Calendar from "react-calendar";
import toast, { LoaderIcon } from "react-hot-toast";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaCheckCircle } from "react-icons/fa";
import { TiDelete, TiEdit } from "react-icons/ti";
import { PhoneInput } from "react-international-phone";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import FormField from "../../components/FormField";
import HealthWorkerDashboardSidebar from "../../components/global/HealthWorkerSidebar";
import ConfirmationModal from "../../components/Modal/Confirmation";
import DeleteModal from "../../components/Modal/Delete";
import TimeModal from "../../components/Modal/Time";
import StatusMessage from "../../components/StatusMessage";
import TextInput from "../../components/TextInput";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import {
  appointmentDataIcons,
  healthWorkerIcons,
  minibar,
} from "../../constants/icons";
import { uploads } from "../../constants/images";
import { reducerCases } from "../../manager/constants";
import { useStateProvider } from "../../manager/StateContext";
import { useAuthManager } from "../../manager/useAuthManager";

import {
  categories,
  categoriesRank,
  daysOfWeek,
  formatAppointmentTime,
  formatDate,
  imageLoader,
  normalizeDate,
  sendError,
  setNextTab,
  setPrevTab,
  transformBackendData,
  useQuery,
  ValidateForm,
} from "../../utils/helpers";
import { colors } from "../../utils/style";

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const LIVE_URL = `${process.env.REACT_APP_API_LIVE_URL}`;

const HealthWorkerEditProfile = () => {
  // console.log(JSON.stringify(Country.getAllCountries(), null, 2));
  const {} = useAuthManager({
    middleware: "auth",
    role: "carer",
    redirectIfAuthenticated: "/health-worker-dashboard",
  });

  const [isOpen, setOpen] = useState(false);  
  const [organizations, setOrganizations] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [calendarValue, setCalendarValue] = useState([]);
  const [states, setStates] = useState(NaijaStates.states());
  const [countries, setCountries] = useState(Country.getAllCountries());
  const [cities, setCities] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [
    {
      health_worker_detail,
      user_availability,
      user_special_availability,
      user_data,
      stage_token,
      token,
    },
    dispatch,
  ] = useStateProvider();

  // console.log(health_worker_detail);

  const [hoveredDay, setHoveredDay] = useState(null);
  const inputRefs = useRef({});

  const [activeTab, setActiveTab] = useState("profile");
  const [activeTab2, setActiveTab2] = useState("weekly_recurring_schedule");
  const [selectedRange, setSelectedRange] = useState({
    from_date: "",
    to_date: formatDate(new Date()),
    from_time: "",
    to_time: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteIsModalOpen] = useState(false);
  const [timeModal, setTimeModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [specialAvailabilities, setSpecialAvailabilities] = useState([]);

  const [imageUrl, setImageUrl] = useState(imageLoader(user_data?.imageUrl));

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setImageUrl(base64String);
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClick = () => {
    document.getElementById("fileInput").click();
  };

  const uploadImage = async (base64String) => {
    const formData = new FormData();
    formData.append("image", base64String);

    try {
      const url = `${LIVE_URL}/api/account/upload-profile-pic`;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${stage_token}`,
          },
        })
        .then((response) => {
          const { data } = response;
          console.log(data);
          toast.success(data.status.desc);
        })
        .catch((error) => {
          const message = sendError(error);
          console.log(message);
          toast.error("There was an error updating profile image");
        });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const fileInputRefs = useRef({
    edu_cert: React.createRef(),
    license: React.createRef(),
    reg_cert: React.createRef(),
    nysc: React.createRef(),
    work_permit: React.createRef(),
    work_id: React.createRef(),
    ref: React.createRef(),
  });
  
  const { accessToken } = useSelector((state) => state.auth);

  const { id: defaultId } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchSpecialAvailabilities = useCallback(
    async (id) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/cooperate/availablility/special/${id}`,
            config
          )
          .then((response) => {
            const { data } = response;
            setSpecialAvailabilities(data?.data);
          })
          .catch((error) => {
            const message = sendError(error);
            console.log(message);
          });
      } catch (error) {
        const message = sendError(error);
        console.log(message);
      }
    },
    [token]
  );

  const [formData, setFormData] = useState({
    first_name: user_data?.first_name,
    last_name: user_data?.last_name,
    mobile: user_data?.mobile,
    city: user_data?.city,
    address: user_data?.address,
    country: user_data?.country,
    state: user_data?.state,
    service_type: user_data?.service_type,
    organisationId: user_data?.organisationId,
    gender: user_data?.gender,
    category: health_worker_detail?.category,
    prof_body: health_worker_detail?.prof_body,
    prof_body_number: health_worker_detail?.prof_body_number,
    prof_body_first: health_worker_detail?.prof_body_first,
    intro_title: health_worker_detail?.intro_title,
    intro_body: health_worker_detail?.intro_body,
    specialty: health_worker_detail?.specialty,
    doc_Specialty: health_worker_detail?.doc_Specialty,
  });

  const [refData, setRefData] = useState({
    ref_name: health_worker_detail?.ref_name ?? "",
    ref_email: health_worker_detail?.ref_email ?? "",
    ref_phone: health_worker_detail?.ref_phone ?? "",
    referee_address: health_worker_detail?.referee_address ?? "",
  });

  const [bankData, setBankData] = useState({
    account_type: health_worker_detail?.account_type ?? "",
    bank_name: health_worker_detail?.bank_name ?? "",
    account_name: health_worker_detail?.account_name ?? "",
    account_number: health_worker_detail?.account_number ?? "",
  });

  const [qualificationData, setQualificationData] = useState({
    edu_cert: null,
    license: null,
    reg_cert: null,
    nysc: null,
    work_permit: null,
    work_id: null,
    ref: null,
  });

  const [appointmentData, setAppointmentData] = useState({
    user_id: "",
    carer_id: "",
    org_id: "",
    monday_opening: "",
    monday_closing: "",
    tuesday_opening: "",
    tuesday_closing: "",
    wednesday_opening: "",
    wednesday_closing: "",
    thursday_opening: "",
    thursday_closing: "",
    friday_opening: "",
    friday_closing: "",
    saturday_opening: "",
    saturday_closing: "",
    sunday_opening: "",
    sunday_closing: "",
  });

  const [consultationData, setConsultationData] = useState({
    consultation_amount: health_worker_detail?.consultation_amount ?? "",
    duration: health_worker_detail?.duration ?? "",
  });

  const [kinData, setKinData] = useState({
    next_of_kin_name: health_worker_detail?.next_of_kin_name ?? "",
    next_of_kin_address: health_worker_detail?.next_of_kin_address ?? "",
    next_of_kin_mobile: health_worker_detail?.next_of_kin_mobile ?? "",
    next_of_kin_relationship:
      health_worker_detail?.next_of_kin_relationship ?? "",
  });

  const [ranks, setRanks] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", activeTab);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  }, [activeTab, navigate, location.pathname, location.search]);

  useEffect(() => {
    if (specialAvailabilities && specialAvailabilities?.length > 0) {
      const transformedData = transformBackendData(specialAvailabilities);
      setCalendarValue(transformedData);
    }
  }, [specialAvailabilities]);

    useEffect(() => {
      if (formData.state) {
        setCities(NaijaStates.lgas(formData.state)?.lgas);
      }
    }, [formData, formData.state]);

  const tab = [
    {
      name: "Profile",
      key: "profile",
      description: "Profile Information.",
    },
    {
      name: "Verification Document",
      key: "qualification",
      description: "Update your document.",
    },
    {
      name: "Availability",
      subName: "(Doctors Only)",
      key: "appointment_setup",
      description: "Date and time setting.",
    },
    {
      name: "Pricing",
      subName: "(Doctors Only)",
      key: "pricing",
      description: "Setup your appointment pricing.",
    },
    {
      name: "Referee",
      key: "referee",
      description: "Who would you like to be your referee.",
    },
    {
      name: "Bank Details",
      key: "bank_details",
      description: "Payment acccount details setup",
    },
    {
      name: "Next of Kin",
      key: "next_of_kin",
      description: "Help us know your next of kin details",
    },
  ];

  const tab2 = [
    {
      name: "Set Availability",
      subName: "(Doctor Only)",
      key: "weekly_recurring_schedule",
      description: "Set general date & time that you are available to offer medical consultations",
    },
    {
      name: "Specific Available Days",
      key: "availablility_schedule",
      description: "Set Specific Available days",
    },
  ];

  const handleTabChange = (data) => {
    setActiveTab(data.key);
  };

  const handleTab2Click = (data) => {
    setActiveTab2(data.key);
  };

  const handleMouseEnter = (name) => {
    setHoveredDay(name);
  };

  const handleMouseLeave = () => {
    setHoveredDay(null);
  };
    
  const handleConsultationChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setConsultationData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleRefereeChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setRefData((refData) => ({
        ...refData,
        [name]: value,
      }));
    }
  };

  const handleBankChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setBankData((bankData) => ({
        ...bankData,
        [name]: value,
      }));
    }
  };

  const handleKinChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setKinData((kinDAta) => ({
        ...kinDAta,
        [name]: value,
      }));
    }
  };

  const fetchSpecialties = async () => {
    try {
      axios
        .get(`${LIVE_URL}/doctors-specialties`)
        .then((response) => {
            const { data } = response;
            setSpecialities(data.status.desc.specialties);
        })
        .catch((error) => {
          const message = sendError(error);
          console.log(message);
        });
    } catch (error) {
      const message = sendError(error);
      console.log(message);
    }
  };

  const fetchOrganizationsDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API_LIVE2_URL}/api/v1/cooperate/organisation/get-all-organisation`,
          config
        )
        .then((response) => {
          const { data } = response;
          // console.log(JSON.stringify(data, null, 2));
          setOrganizations(data?.data?.organisations);
        })
        .catch((error) => {
          const message = sendError(error);
        });
    } catch (error) {
      const message = sendError(error);
      console.log(message);
    }
  };

  useEffect(() => {
    const queryTab = query.get("tab");
    setActiveTab(queryTab);

    fetchSpecialties();
    fetchOrganizationsDetails();
    fetchSpecialAvailabilities(user_data?.id);
  }, []);

  useEffect(() => {
    setAppointmentData((prev) => ({
      ...prev,
      user_id: user_data?.id || "",
      carer_id: health_worker_detail?.carer_id || "",
      org_id: user_data?.organisationId || "",
    }));
  }, [user_data, health_worker_detail]);


  useEffect(() => {
    setAppointmentData(user_availability);
  }, [user_availability]);

  const checkClosing = (text) => {
    return text.includes("_closing");
  }

  const handleAppointmentChange = (e) => {
    const { name, value } = e.target;
    const isClosing = checkClosing(name);

    if (isClosing) {
      const day = name.split("_")[0];
      const checkTime = day + "_opening";
      const openingValue = parseInt(appointmentData[checkTime], 10);
      const closingValue = parseInt(value, 10);

      if (isNaN(openingValue)) {
        dispatch({
          type: reducerCases.SET_MESSAGE_STATUS,
          message_status: {
            type: "error",
            message: "Please set opening time first."
          },
        });
        return;
      }

      if (
        isNaN(closingValue) ||
        closingValue <= openingValue ||
        closingValue > 23
      ) {
         dispatch({
           type: reducerCases.SET_MESSAGE_STATUS,
           message_status: {
             type: "error",
             message: "The closing time selected must be later than the opening time and must not exceed 11:00 PM."
           },
         });
        return;
      }
    }

    setAppointmentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle the change event
  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;

      if (name === "category"){
        const categoryRanks = categoriesRank.find(
          (category) => category.name === value
        );
        setRanks(categoryRanks ? categoryRanks.ranks : []);
      }

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
    }
  };

  const handleClick = (key) => {
    const fileInputRef = fileInputRefs.current[key];
    console.log("File input clicked:", key);

    if (fileInputRef && fileInputRef.current) {
      // Make the file input visible and positioned off-screen
      fileInputRef.current.style.position = "absolute";
      fileInputRef.current.style.left = "-9999px";

      // Trigger a click on the file input
      fileInputRef.current.click();

      // After the user selects a file, reset the styles to hide the file input
      fileInputRef.current.addEventListener("change", () => {
        fileInputRef.current.style.position = "none";
        fileInputRef.current.style.left = "none";
      });
    }
  };

  // Handle file uploads
  const handleFileChange = (e, key) => {
    const selectedFile = e.currentTarget.files[0];

    if (selectedFile) {
      setQualificationData((prevFormData) => ({
        ...prevFormData,
        [key]: selectedFile,
      }));
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const keys = ["organisationId", "category", "doc_Specialty"];
    const allow = ValidateForm(formData, keys);

    if (allow.status) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const url = `${BASE_URL}/api/v1/cooperate/health-worker/update-health-worker-information/${defaultId}`;
        axios
          .put(url, { ...formData }, config)
          .then((response) => {
            setIsLoading(false);
            const { data } = response;
            toast.success(
              data.message + " Your profile would undergo verification again."
            );
            setNextTab(activeTab, tab, setActiveTab);
          })
          .catch((error) => {
            setIsLoading(false);
            const message = sendError(error);
            console.log(message);
            toast.error(
              "There was an error updating your information! Please try again."
            );
          });
      } catch (error) {
        setIsLoading(false);
        toast.error(
          "There was an error updating your information! Please try again."
        );
      }
    }else{
      setIsLoading(false);
      dispatch({
        type: reducerCases.SET_MESSAGE_STATUS,
        message_status: {
          type: "error",
          message: allow.message
        },
      });
      toast.error(allow.message);
    }
  };

  const handleUploadDocument = async () => {
    const health = {
      edu_cert: qualificationData.edu_cert,
      license: qualificationData.license,
      reg_cert: qualificationData.reg_cert,
      nysc: qualificationData.nysc,
      work_id: qualificationData.work_id,
      nat_id: qualificationData.nat_id,
      ref: qualificationData.ref,
    };

    const formDataToSend = new FormData();

    Object.entries(health).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          formDataToSend.append(`${key}[${index}]`, element);
        });
      } else {
        formDataToSend.append(key, value === "null" ? null : value);
      }
    });

    Object.entries(formData).forEach(([key, file]) => {
      if (key.startsWith("file") && file) {
        formDataToSend.append(key, file, file.name);
      }
    });

    setIsLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${stage_token}`,
        },
      };

      const url = `${LIVE_URL}/api/carer/verify`;
      axios
        .post(url, formDataToSend, config)
        .then((response) => {
          setIsLoading(false);
          const { data } = response;
          toast.success(
            data.status.desc + " Your profile would undergo verification again."
          );
          setNextTab(activeTab, tab, setActiveTab);
        })
        .catch((error) => {
          setIsLoading(false);

          const message = sendError(error);
          console.log(message);
          toast.error(
            "There was an error updating your information! Please try again."
          );
        });
    } catch (error) {
      setIsLoading(false);
      toast.error("There was an error updating your file! Please try again.");
    }
  };

  const handleAvailableDay = () => {
    alert();
  };

  const handleUpdateAvailability = () => {
    setIsLoading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      
      axios
        .post(
          `${BASE_URL}/api/v1/cooperate/availablility/create-update`,
          { ...appointmentData },
          config
        )
        .then((response) => {
          setIsLoading(false);
          const { data } = response;
          toast.success(
            data.message + " Your profile would undergo verification again."
          );
          setNextTab(activeTab, tab, setActiveTab);
        })
        .catch((error) => {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.message);
        });
    } catch (error) {
      setIsLoading(false);
      const message = sendError(error);
      console.log(message.message);
    }
  };

  const handleDeleteAvailability = (day) => {
    setAppointmentData((prevData) => ({
      ...prevData,
      [`${day}_opening`]: "",
      [`${day}_closing`]: "",
    }));
  };

  const handleDateChange = (value) => {
    setSelectedRange({
      from_date: formatDate(value[0]),
      to_date: formatDate(value[0]),
      from_time: 10,
      to_time: 19,
    });
    setTimeModal(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleConfirmCreateDate = () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(
          `${BASE_URL}/api/v1/cooperate/availablility/special/create`,
          { ...selectedRange },
          config
        )
        .then((response) => {
          setIsLoading(false);
          setIsModalOpen(!isModalOpen);
          const { data } = response;
          toast.success(data.message);

          setSelectedRange({
            from_date: "",
            to_date: "",
            from_time: "",
            to_time: "",
          });
          fetchSpecialAvailabilities(user_data?.id);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsModalOpen(!isModalOpen);

          const message = sendError(error);
          toast.error(message.message);
        });
    } catch (error) {
      setIsLoading(false);
      const message = sendError(error);
      console.log(message.message);
    }
  };

  const handleCloseTimeModal = () => {
    setTimeModal(!timeModal);
  };

  const handleTimeChange = (e) => {
      const { name, value } = e.target;
      if(name === "to_time"){
        const openingValue = parseInt(selectedRange.from_time, 10);
        const closingValue = parseInt(value, 10);

        if (isNaN(openingValue)) {
          toast.error("Please set opening time first.");
          return;
        }

        if (
          isNaN(closingValue) ||
          closingValue <= openingValue ||
          closingValue > 23
        ) {
          toast.error(
            "The closing time selected must be later than the opening time and must not exceed 11:00 PM."
          );
          return;
        }
      }
      
      setSelectedRange((selectedRange) => ({
        ...selectedRange,
        [name]: value,
      }));
  };

  const handleDateTimeSubmit = () => {
    setTimeModal(!timeModal);
    setIsModalOpen(!isModalOpen);
  };

  const handleDeleteModal = (id) => {
    setSelectedValue(id);
    setDeleteIsModalOpen(!isDeleteModalOpen);
  };

  const handleConfirmDeleteDate = () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .delete(
          `${BASE_URL}/api/v1/cooperate/availablility/special/${selectedValue}`,
          config
        )
        .then((response) => {
          setIsLoading(false);
          const { data } = response;
          toast.success(data.message);
          setDeleteIsModalOpen(!isDeleteModalOpen);
          fetchSpecialAvailabilities(user_data?.id);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.message);
        });
    } catch (error) {
      setIsLoading(false);
      const message = sendError(error);
      console.log(message.message);
    }
  };

  const isDateInRanges = (date) => {
    const normalizedDate = normalizeDate(date);
    return calendarValue.some(
      ([start, end]) =>
        normalizedDate >= normalizeDate(start) &&
        normalizedDate <= normalizeDate(end)
    );
  };

  const handleUpdateConsultation = () => {
    Swal.fire({
      title: "Update Pricing",
      text: "Are you sure you want to update your pricing?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      cancelButtonColor: "red",
      confirmButtonColor: "#00aaff",
      customClass: {
        title: "swal2-title-custom",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${stage_token}`,
            },
          };

          setIsLoading(true);
          const url = `${process.env.REACT_APP_API_LIVE_URL}/api/telemedicine/hw-settings`;
          axios
            .put(url, { ...consultationData }, config)
            .then((response) => {
              setIsLoading(false);
              const { data } = response;
              if (data.status.code === 100) {
                toast.success(data.status.desc);
                setOpen();
                setNextTab(activeTab, tab, setActiveTab);
              }
            })
            .catch((error) => {
              setIsLoading(false);
              const message = sendError(error);
              console.log(message);
              toast.error(message.message);
            });
        } catch (error) {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.error);
        }
      }
    });
  };

  const handleUpdateReferee = () => {
    Swal.fire({
      title: "Update Referee",
      text: "Are you sure you want to update your referee details? Your account would have to undergo verification again.",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      cancelButtonColor: "red",
      confirmButtonColor: "#00aaff",
      customClass: {
        title: "swal2-title-custom",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          setIsLoading(true);
          const url = `${BASE_URL}/api/v1/cooperate/health-worker/update-health-worker-information/${defaultId}`;
          axios
            .put(url, { ...refData }, config)
            .then((response) => {
              setIsLoading(false);
              const { data } = response;
              toast.success(
                data.message + " Your profile would undergo verification again."
              );
              setNextTab(activeTab, tab, setActiveTab);
            })
            .catch((error) => {
              setIsLoading(false);
              const message = sendError(error);
              console.log(message);
              toast.error(message.message);
            });
        } catch (error) {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.error);
        }
      }
    });
  };

  const handleUpdateBank = () => {
    Swal.fire({
      title: "Update Bank Details",
      text: "Are you sure you want to update your bank details? Your account would have to undergo verification again.",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      cancelButtonColor: "red",
      confirmButtonColor: "#00aaff",
      customClass: {
        title: "swal2-title-custom",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          setIsLoading(true);
          const url = `${BASE_URL}/api/v1/cooperate/health-worker/update-health-worker-information/${defaultId}`;
          axios
            .put(url, { ...bankData }, config)
            .then((response) => {
              setIsLoading(false);
              const { data } = response;
              toast.success(data.message);
              setNextTab(activeTab, tab, setActiveTab);
            })
            .catch((error) => {
              setIsLoading(false);
              const message = sendError(error);
              console.log(message);
              toast.error(message.message);
            });
        } catch (error) {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.error);
        }
      }
    });
  };

  const handleUpdateKin = () => {
    Swal.fire({
      title: "Update Next of Kin Details",
      text: "Are you sure you want to update your next of kin details? Your account would have to undergo verification again.",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      cancelButtonColor: "red",
      confirmButtonColor: "#00aaff",
      customClass: {
        title: "swal2-title-custom",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          setIsLoading(true);
          const url = `${BASE_URL}/api/v1/cooperate/health-worker/update-health-worker-information/${defaultId}`;
          axios
            .put(url, { ...kinData }, config)
            .then((response) => {
              setIsLoading(false);
              const { data } = response;
              toast.success(
                data.message + " Your profile would undergo verification again."
              );
              setNextTab(activeTab, tab, setActiveTab);
            })
            .catch((error) => {
              setIsLoading(false);
              const message = sendError(error);
              console.log(message);
              toast.error(message.message);
            });
        } catch (error) {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.error);
        }
      }
    });
  };

  const handleNext = () => {
    setNextTab(activeTab, tab, setActiveTab);
  }

  const handlePrev = () => {
    setPrevTab(activeTab, tab, setActiveTab);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex sm:justify-between bg-[#F9F9F9]  font-Montserrat custom-scrollbar">
      <HealthWorkerDashboardSidebar isOpen={isOpen} setOpen={setOpen} />

      <div className="sm:w-screen space-x-2">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />

        <div className="block sm:flex mt-4 w-full">
          <div className="sm:py-6 bg-white rounded-[20px] p-2 flex items-center justify-between">
            <div>
              <ol className="relative  text-gray-500 sm:border-l sm:y-8 mx-8  border-gray-200 dark:border-gray-700 dark:text-gray-400 ">
                {tab?.map((item, index) => (
                  <li
                    className={`${
                      item.key === activeTab ? "block" : "hidden sm:block"
                    } sm:mb-10  ml-8 sm:ml-12 cursor-pointer`}
                    onClick={() => handleTabChange(item)}
                  >
                    <span
                      class={`absolute  text-white font-bold font-Roboto flex items-center justify-center w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] ${
                        activeTab === item.key
                          ? "bg-[#47A7FD] "
                          : "bg-[#C9E5FF]"
                      }
                rounded-xl -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900`}
                    >
                      {index + 1}
                    </span>
                    <h3 className="font-bold text-[15.5px] leading-tight text-[#303030] ">
                      {item.name}
                      {
                      item.subName ? <span className="text-[10px] ml-1">{item.subName}</span> : null
                      }
                    </h3>
                    <p className="text-[10px] text-start text-[#303030] max-w-xs font-Poppins">
                      {item.description}
                    </p>
                  </li>
                ))}
              </ol>
            </div>

            <div className="flex sm:hidden cursor-pointer items-center space-x-2 mr-2">
              <div onClick={handlePrev}>
                <FaArrowAltCircleLeft size={25} color={colors.primary} />
              </div>
              <div onClick={handleNext}>
                <FaArrowAltCircleRight size={25} color={colors.primary} />
              </div>
            </div>
          </div>

          <div className="sm:ml-4 mt-4 sm:mt-0 px-3 py-6 bg-white rounded-[20px] w-full h-screen overflow-y-auto custom-scrollbar">
            <StatusMessage />

            {activeTab === "profile" ? (
              <div className="">
                <div className="mb-3">
                  <h5 className="font-bold text-sm">Profile Information</h5>
                  <p className="text-xs">Edit your profile information</p>
                </div>

                <div className="flex flex-row justify-center my-3">
                  <div className="relative">
                    <img
                      src={imageUrl ?? uploads.avatar}
                      alt={user_data?.first_name}
                      className="rounded-full h-[80px] w-[80px] border-2 border-bg"
                      onClick={handleEditClick}
                    />

                    <button
                      onClick={handleEditClick}
                      className="absolute bottom-0 right-0 z-5 bg-[#47A7FD] rounded-full p-1"
                    >
                      <TiEdit size={18} color="#000" />
                    </button>

                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center w-full  gap-x-4">
                  <div className="w-full">
                    <label className="font-medium text-[10px]">
                      First name
                    </label>
                    <FormField
                      type="text"
                      name="first_name"
                      placeholder="Joe"
                      value={formData.first_name}
                      onChange={(e) => handleChange(e)}
                      required
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div className="w-full">
                    <label className="font-medium text-[10px]">Last name</label>
                    <FormField
                      placeholder="Doe"
                      name="last_name"
                      value={formData.last_name}
                      onChange={(e) => handleChange(e)}
                      type="text"
                      required
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div className="w-full">
                    <label className="font-medium text-[10px] max-w-xl ">
                      Phone number
                    </label>
                    <PhoneInput
                      defaultCountry="ng"
                      type="phone"
                      name="mobile"
                      value={formData.mobile}
                      onChange={(e) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          ["mobile"]: e,
                        }))
                      }
                      containerStyle={{
                        border: "0.5rem ",
                        width: "100%",
                      }}
                      inputStyle={{
                        background: "#F3F3F3",
                        width: "100%",
                        padding: "0.5rem 1rem",
                        borderRadius: "0.5rem",
                        marginBottom: "1rem",
                        border: "0.5rem",
                      }}
                    />
                  </div>

                  <div className="w-full relative">
                    <label className="font-medium text-[10px] max-w-xl">
                      Gender
                    </label>
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={(e) => handleChange(e)}
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                      <img
                        src={appointmentDataIcons.dropdownIcon}
                        className="w-3 h-3"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <label className="font-medium text-[10px] max-w-xl">
                      Address
                    </label>
                    <FormField
                      type="address"
                      name="address"
                      value={formData.address}
                      onChange={(e) => handleChange(e)}
                      placeholder="Ikeja lagos State"
                      required
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div className="w-full relative">
                    <label className="font-medium text-[10px] max-w-xl">
                      State
                    </label>
                    <select
                      name="state"
                      value={formData.state}
                      onChange={(e) => handleChange(e)}
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
                    >
                      <option value="" disabled>
                        Select State
                      </option>
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                    <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                      <img
                        src={appointmentDataIcons.dropdownIcon}
                        className="w-3 h-3"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="w-full relative">
                    <label className="font-medium text-[10px] max-w-xl">
                      City
                    </label>
                    <select
                      name="city"
                      value={formData.city}
                      onChange={(e) => handleChange(e)}
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-3 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
                    >
                      <option value="" disabled>
                        Select City
                      </option>
                      {cities?.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                    <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                      <img
                        src={appointmentDataIcons.dropdownIcon}
                        className="w-3 h-3"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="w-full relative">
                    <label className="font-medium text-[10px] max-w-xl">
                      Country
                    </label>
                    <select
                      name="country"
                      value={formData.country}
                      onChange={(e) => handleChange(e)}
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries.map((country, index) => (
                        <option key={index} value={country?.name}>
                          {country?.name}
                        </option>
                      ))}
                    </select>
                    <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                      <img
                        src={appointmentDataIcons.dropdownIcon}
                        className="w-3 h-3"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="relative">
                      <label className="font-medium text-[10px] max-w-xl">
                        Organisation
                      </label>
                      <select
                        name="organisationId"
                        className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                        value={formData.organisationId}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {organizations?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.organisationName}
                          </option>
                        ))}
                      </select>
                      <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                        <img
                          src={appointmentDataIcons.dropdownIcon}
                          className="w-3 h-3"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full relative">
                    <label className="font-medium text-[10px] max-w-xl">
                      Health Worker Category
                    </label>
                    <select
                      name="category"
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                      value={formData.category}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select category</option>
                      {categories?.map((item, index) => (
                        <option key={index} value={item?.value}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                      <img
                        src={appointmentDataIcons.dropdownIcon}
                        className="w-3 h-3"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="w-full relative">
                    <label className="font-medium text-[10px] max-w-xl">
                      Approximate Rank - ({formData.specialty})
                    </label>
                    <select
                      name="specialty"
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                      value={formData.specialty}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select</option>
                      {ranks?.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                      <img
                        src={appointmentDataIcons.dropdownIcon}
                        className="w-3 h-3"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="w-full relative">
                    <label className="font-medium text-[10px] max-w-xl">
                      Medical Specialty (Doctors only)
                    </label>
                    <select
                      value={formData.doc_Specialty}
                      id="doc_Specialty"
                      name="doc_Specialty"
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select</option>
                      {specialities?.map((item) => (
                        <option key={item.value} value={item.specialties}>
                          {item.specialties}
                        </option>
                      ))}
                    </select>
                    <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                      <img
                        src={appointmentDataIcons.dropdownIcon}
                        className="w-3 h-3"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <h5 className="font-bold text-[12px]">Profession Details</h5>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    <div className="w-full">
                      <label className="font-medium text-[10px] max-w-xl">
                        Professional Title
                      </label>
                      <FormField
                        value={formData.prof_body}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        name="prof_body"
                        placeholder=""
                        required
                        className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                      />
                    </div>

                    <div className="w-full">
                      <label className="font-medium text-[10px] max-w-xl">
                        Professional Registration Number
                      </label>
                      <FormField
                        value={formData.prof_body_number}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        name="prof_body_number"
                        placeholder=""
                        required
                        className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                      />
                    </div>

                    <div className="w-full flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
                      <div>
                        <label className="font-medium text-[10px]">
                          Professional Date First Registered
                        </label>
                        <FormField
                          value={formData.prof_body_first}
                          onChange={(e) => handleChange(e)}
                          type="date"
                          name="prof_body_first"
                          placeholder="Joe"
                          required
                          className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <h5 className="font-bold text-[12px]">Bio</h5>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    <div className="w-full">
                      <label className="font-medium text-[10px] max-w-xl">
                        Introduction Title
                      </label>
                      <FormField
                        value={formData.intro_title}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        name="intro_title"
                        placeholder=""
                        required
                        className="w-full mb-4 mt bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                      />
                    </div>

                    <div className="w-full">
                      <label className="font-medium text-[10px] max-w-xl">
                        Introduction
                      </label>
                      <textarea
                        value={formData.intro_body}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        name="intro_body"
                        placeholder=""
                        required
                        rows="1"
                        className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-row justify-center items-center">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="text-white w-[20rem] bg-bgbutton hover:bg-bgbutton focus:ring-4 
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
             dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800"
                  >
                    {isLoading ? (
                      <div className="flex justify-center">
                        <LoaderIcon size={20} color="white" />
                      </div>
                    ) : (
                      <p>Update</p>
                    )}
                  </button>
                </div>
              </div>
            ) : activeTab === "qualification" ? (
              <div className="sm:mx-5">
                <div className="mb-3">
                  <h5 className="font-bold text-sm">Verification Document</h5>
                  <p className="text-xs">Update your doucuments</p>

                  <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center w-full  sm:gap-x-4 py-2">
                    <div className="relative w-full">
                      {health_worker_detail?.edu_cert && (
                        <div className="absolute top-4 right-0">
                          <FaCheckCircle size="20" color={colors.primary} />
                        </div>
                      )}
                      <div className="w-full mt-2">
                        <label className="font-semibold max-w-xl text-[10px] text-black">
                          <span className="text-[#D60000]">*</span>
                          <i>Upload Education Certificate</i>
                        </label>
                        <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                          <label
                            htmlFor="fileInput-edu_cert"
                            className="cursor-pointer"
                          >
                            <img
                              src={healthWorkerIcons.dashicons}
                              className="px-[10rem] py-2 items-center"
                              alt=""
                            />
                            <p className="text-center text-[#47A7FD] text-sm">
                              <span className="text-black">
                                {qualificationData.edu_cert &&
                                qualificationData.edu_cert.name
                                  ? `Selected File: ${qualificationData.edu_cert.name}`
                                  : " Click here to Add your files here "}
                              </span>
                            </p>
                          </label>
                          <input
                            id="fileInput-edu_cert"
                            name="edu_cert"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, "edu_cert")}
                            ref={fileInputRefs.edu_cert}
                          />
                        </div>
                        <h1 className="max-w-[15rem] text-[8px]">
                          PDF, JPEG & PNG are accepted. File should be within
                          10mb.
                        </h1>
                      </div>
                    </div>

                    <div className="relative w-full">
                      {health_worker_detail?.license && (
                        <div className="absolute top-4 right-0">
                          <FaCheckCircle size="20" color={colors.primary} />
                        </div>
                      )}
                      <div className="w-full mt-2">
                        <label className="font-semibold max-w-xl text-[10px] text-black">
                          <span className="text-[#D60000]">*</span>
                          <i> Upload license</i>
                        </label>
                        <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                          <label
                            htmlFor="fileInput-license"
                            className="cursor-pointer"
                          >
                            <img
                              src={healthWorkerIcons.dashicons}
                              className="px-[10rem] py-2 items-center"
                              onClick={() => handleClick("license")}
                              alt=""
                            />
                            <p
                              className="text-center text-[#47A7FD] text-sm cursor-auto"
                              onClick={() => handleClick("license")}
                            >
                              <span className="text-black">
                                {qualificationData.license &&
                                qualificationData.license.name
                                  ? `Selected File: ${qualificationData.license.name}`
                                  : " Click here to Add your files here "}
                              </span>
                            </p>
                          </label>
                          <input
                            id="fileInput-license"
                            name="license"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, "license")}
                            ref={fileInputRefs.license}
                          />
                        </div>
                        <h1 className="max-w-[15rem] text-[8px]">
                          PDF, JPEG & PNG are accepted. File should be within
                          10mb.
                        </h1>
                      </div>
                    </div>

                    <div className="relative w-full">
                      {health_worker_detail?.reg_cert && (
                        <div className="absolute top-4 right-0">
                          <FaCheckCircle size="20" color={colors.primary} />
                        </div>
                      )}
                      <div className="w-full mt-2">
                        <label className="font-semibold max-w-xl text-[10px] text-black">
                          <span className="text-[#D60000]">*</span>
                          <i> Upload Certificate of Registration</i>
                        </label>
                        <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                          <label
                            htmlFor="fileInput-reg_cert"
                            className="cursor-pointer"
                          >
                            <img
                              src={healthWorkerIcons.dashicons}
                              className="pl-[8rem] py-2 items-center"
                              onClick={() => handleClick("reg_cert")}
                              alt=""
                            />
                            <p
                              className="text-center text-[#47A7FD] text-sm"
                              onClick={() => handleClick("reg_cert")}
                            >
                              <span className="text-black">
                                {qualificationData.reg_cert &&
                                qualificationData.reg_cert.name
                                  ? `Selected File: ${qualificationData.reg_cert.name}`
                                  : " Click here to Add your files here "}
                              </span>
                            </p>
                          </label>
                          <input
                            id="fileInput-reg_cert"
                            name="reg_cert"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, "reg_cert")}
                            ref={fileInputRefs.reg_cert}
                          />
                        </div>
                        <h1 className="max-w-[15rem] text-[8px]">
                          PDF, JPEG & PNG are accepted. File should be within
                          10mb.
                        </h1>
                      </div>
                    </div>

                    <div className="relative w-full">
                      {health_worker_detail?.nysc_cert && (
                        <div className="absolute top-4 right-0">
                          <FaCheckCircle size="20" color={colors.primary} />
                        </div>
                      )}
                      <div className="w-full mt-2">
                        <label className="font-semibold max-w-xl text-[10px] text-black">
                          <span className="text-[#D60000]">*</span>
                          <i> Upload NYSC Certificate</i>
                        </label>
                        <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                          <label
                            htmlFor="fileInput-nysc"
                            className="cursor-pointer"
                          >
                            <img
                              src={healthWorkerIcons.dashicons}
                              className="pl-[8rem] py-2 items-center"
                              onClick={() => handleClick("nysc")}
                              alt=""
                            />
                            <p
                              className="text-center text-[#47A7FD] text-sm"
                              onClick={() => handleClick("nysc")}
                            >
                              <span className="text-black">
                                {qualificationData.nysc &&
                                qualificationData.nysc.name
                                  ? `Selected File: ${qualificationData.nysc.name}`
                                  : " Click here to Add your files here "}
                              </span>
                            </p>
                          </label>
                          <input
                            id="fileInput-nysc"
                            name="nysc"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, "nysc")}
                            ref={fileInputRefs.nysc}
                          />
                        </div>
                        <h1 className="max-w-[15rem] text-[8px]">
                          PDF, JPEG & PNG are accepted. File should be within
                          10mb.
                        </h1>
                      </div>
                    </div>

                    <div className="relative w-full">
                      {health_worker_detail?.work_permit && (
                        <div className="absolute top-4 right-0">
                          <FaCheckCircle size="20" color={colors.primary} />
                        </div>
                      )}
                      <div className="w-full mt-2">
                        <label className="font-semibold max-w-xl text-[10px] text-black">
                          <span className="text-[#D60000]">*</span>
                          <i> Upload National ID</i>
                        </label>
                        <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                          <label
                            htmlFor="fileInput-work_permit"
                            className="cursor-pointer"
                          >
                            <img
                              src={healthWorkerIcons.dashicons}
                              className="pl-[8rem] py-2 items-center"
                              onClick={() => handleClick("work_permit")}
                              alt=""
                            />
                            <p
                              className="text-center text-[#47A7FD] text-sm"
                              onClick={() => handleClick("work_permit")}
                            >
                              <span className="text-black">
                                {qualificationData.work_permit &&
                                qualificationData.work_permit.name
                                  ? `Selected File: ${qualificationData.work_permit.name}`
                                  : " Click here to Add your files here "}
                              </span>
                            </p>
                          </label>
                          <input
                            id="fileInput-work_permit"
                            name="work_permit"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, "work_permit")}
                            ref={fileInputRefs.work_permit}
                          />
                        </div>
                        <h1 className="max-w-[15rem] text-[8px]">
                          PDF, JPEG & PNG are accepted. File should be within
                          10mb.
                        </h1>
                      </div>
                    </div>

                    <div className="relative w-full">
                      {health_worker_detail?.work_id && (
                        <div className="absolute top-4 right-0">
                          <FaCheckCircle size="20" color={colors.primary} />
                        </div>
                      )}
                      <div className="w-full mt-2">
                        <label className="font-semibold max-w-xl text-[10px] text-black">
                          <span className="text-[#D60000]">*</span>
                          <i> Upload Work ID</i>
                        </label>
                        <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                          <label
                            htmlFor="fileInput-work_id"
                            className="cursor-pointer"
                          >
                            <img
                              src={healthWorkerIcons.dashicons}
                              className="pl-[8rem] py-2 items-center"
                              onClick={() => handleClick("work_id")}
                              alt=""
                            />
                            <p
                              className="text-center text-[#47A7FD] text-sm"
                              onClick={() => handleClick("work_id")}
                            >
                              <span className="text-black">
                                {qualificationData.work_id &&
                                qualificationData.work_id.name
                                  ? `Selected File: ${qualificationData.work_id.name}`
                                  : " Click here to Add your files here "}
                              </span>
                            </p>
                          </label>
                          <input
                            id="fileInput-work_id"
                            name="work_id"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, "work_id")}
                            ref={fileInputRefs.work_id}
                          />
                        </div>
                        <h1 className="max-w-[15rem] text-[8px]">
                          PDF, JPEG & PNG are accepted. File should be within
                          10mb.
                        </h1>
                      </div>
                    </div>

                    <div className="relative w-full">
                      {health_worker_detail?.referee && (
                        <div className="absolute top-4 right-0">
                          <FaCheckCircle size="20" color={colors.primary} />
                        </div>
                      )}
                      <div className="w-full mt-2">
                        <label className="font-semibold max-w-xl text-[10px] text-black">
                          <span className="text-[#D60000]">*</span>
                          <i> Upload Referee Document</i>
                        </label>
                        <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                          <label
                            htmlFor="fileInput-ref"
                            className="cursor-pointer"
                          >
                            <img
                              src={healthWorkerIcons.dashicons}
                              className="pl-[8rem] py-2 items-center"
                              onClick={() => handleClick("ref")}
                              alt=""
                            />
                            <p
                              className="text-center text-[#47A7FD] text-sm"
                              onClick={() => handleClick("ref")}
                            >
                              <span className="text-black">
                                {qualificationData.ref &&
                                qualificationData.ref.name
                                  ? `Selected File: ${qualificationData.ref.name}`
                                  : " Click here to Add your files here "}
                              </span>
                            </p>
                          </label>
                          <input
                            id="fileInput-ref"
                            name="ref"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, "ref")}
                            ref={fileInputRefs.ref}
                          />
                        </div>
                        <h1 className="max-w-[15rem] text-[8px]">
                          PDF, JPEG & PNG are accepted. File should be within
                          10mb.
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-row justify-center items-center">
                  <button
                    type="submit"
                    onClick={handleUploadDocument}
                    className="text-white w-[20rem] bg-bgbutton hover:bg-bgbutton focus:ring-4 
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
             dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800"
                  >
                    {isLoading ? (
                      <div className="flex justify-center">
                        <LoaderIcon size={20} color="white" />
                      </div>
                    ) : (
                      <p>Upload</p>
                    )}
                  </button>
                </div>
              </div>
            ) : activeTab === "appointment_setup" ? (
              <div className="m-auto">
                <div className="flex justify-center">
                  <div className="block sm:flex items-center sm:-mx-4 space-x-2 overflow-x-auto overflow-y-hidden sm:justify-center flex-nowrap bg-bgbutton sm:w-[550px] my-2">
                    {tab2.map((item, index) => (
                      <div
                        onClick={() => handleTab2Click(item)}
                        key={index}
                        rel="noopener noreferrer"
                        className={`items-center flex-shrink-0 px-5 text-white cursor-pointer py-1`}
                      >
                        <p
                          className={`${
                            activeTab2 === item.key
                              ? "border-b-4 border-red-500 mb-2"
                              : ""
                          } font-bold text-sm`}
                        >
                          {item.name}
                          <span className="text-[10px] ml-1">{item.subName }</span> 
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                {activeTab2 === "weekly_recurring_schedule" ? (
                  <div>
                    <div className="flex flex-row items-center justify-between">
                      <div>
                        <h5 className="font-bold text-md">{tab2[ 0 ][ "name" ]}
                        <span className="text-[10px] ml-1"> {tab2[ 0 ][ "subName" ]} </span> 
                        </h5>
                        <p className="text-xs">{tab2[0]["description"]}</p>
                      </div>
                    </div>

                    {daysOfWeek.map((day, index) => (
                      <div
                        key={day}
                        ref={(el) => (inputRefs.current[`${day}`] = el)}
                        onMouseEnter={() => handleMouseEnter(`${day}`)}
                        onMouseLeave={handleMouseLeave}
                        className="relative my-5"
                      >
                        <div className="grid grid-cols-1 sm:grid-cols-2 place-content-center">
                          <div className="flex flex-row items-center space-x-1">
                            <p className="font-bold text-sm">{index + 1}).</p>
                            <p className="font-bold text-[10px] sm:text-sm">
                              {day.charAt(0).toUpperCase() + day.slice(1)}:
                            </p>
                          </div>
                          <div className="grid-span-2 w-full">
                            <div className="flex flex-row flex-wrap items-center space-x-4">
                              <div className="mr-3">
                                <label className="text-sm font-light flex flex-col items-center">
                                  <div className="mr-2 flex flex-row items-center space-x-2">
                                    <p className="text-left text-[10px] font-semibold">
                                      Opening Time:
                                    </p>
                                    <p>
                                      {formatAppointmentTime(
                                        appointmentData[`${day}_opening`]
                                      )}
                                    </p>
                                  </div>

                                  <div>
                                    <input
                                      type="range"
                                      name={`${day}_opening`}
                                      min="0"
                                      max="23"
                                      step="1"
                                      value={appointmentData[`${day}_opening`]}
                                      onChange={handleAppointmentChange}
                                      className="h-4 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-bgbutton"
                                    />
                                  </div>
                                </label>
                              </div>

                              <div>
                                <label className="text-sm font-light flex flex-col items-center">
                                  <div className="ml-2 flex flex-row items-center space-x-2">
                                    <p className="text-right text-[10px] font-semibold">
                                      Closing Time:
                                    </p>
                                    <p>
                                      {formatAppointmentTime(
                                        appointmentData[`${day}_closing`]
                                      )}
                                    </p>
                                  </div>

                                  <div>
                                    <input
                                      type="range"
                                      name={`${day}_closing`}
                                      min="0"
                                      max="23"
                                      step="1"
                                      value={appointmentData[`${day}_closing`]}
                                      onChange={handleAppointmentChange}
                                      className="h-4 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-bgbutton"
                                    />
                                  </div>
                                </label>
                              </div>

                              <div>
                                <button
                                  onClick={() => handleDeleteAvailability(day)}
                                  className="left-4 z-10 flex flex-row items-center"
                                >
                                  <TiDelete size={25} color={colors.danger} />
                                  <p className="text-[10px] font-bold text-red-600">
                                    Reset
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="w-full flex flex-row justify-end items-center">
                      <button
                        type="submit"
                        onClick={handleUpdateAvailability}
                        className="text-white w-[5rem] bg-bgbutton hover:bg-bgbutton focus:ring-4 
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
             dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800"
                      >
                        {isLoading ? (
                          <div className="flex justify-center">
                            <LoaderIcon size={20} color="white" />
                          </div>
                        ) : (
                          <p>Update</p>
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <div>
                      <h5 className="font-bold text-md">{tab2[1]["name"]}</h5>
                      <p className="text-xs">
                        <p className="text-xs">{tab2[1]["description"]}</p>
                      </p>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-2">
                      <div className="mt-2">
                        <Calendar
                          allowPartialRange={true}
                          tileClassName={({ date }) =>
                            isDateInRanges(date) ? "highlight" : null
                          }
                          selectRange={true}
                          onChange={handleDateChange}
                        />
                      </div>

                      <div className="mt-4 sm:mt-0">
                        <div>
                          {specialAvailabilities
                            ?.slice(0, 5)
                            .map((availability, index) => (
                              <div
                                key={index}
                                className="relative bg-white rounded-lg py-1 px-2 my-2"
                              >
                                <div className="flex items-center justify-between">
                                  <p className="font-bold text-xs">
                                    From Date:
                                  </p>
                                  <p className="text-xs">
                                    {formatDate(availability.from_date)}
                                  </p>
                                </div>

                                <div className="flex items-center justify-between">
                                  <p className="font-bold text-xs">To Date:</p>
                                  <p className="text-xs">
                                    {formatDate(availability.to_date)}
                                  </p>
                                </div>

                                <hr />

                                <div className="flex items-center justify-between">
                                  <p className="font-bold text-xs">Start:</p>
                                  <p className="text-xs">
                                    {formatAppointmentTime(
                                      availability.from_time
                                    )}
                                  </p>
                                </div>

                                <div className="flex items-center justify-between">
                                  <p className="font-bold text-xs">Close:</p>
                                  <p className="text-xs">
                                    {formatAppointmentTime(
                                      availability.to_time
                                    )}
                                  </p>
                                </div>

                                <button
                                  onClick={() =>
                                    handleDeleteModal(availability.id)
                                  }
                                  className="absolute right-0 -top-3"
                                >
                                  <TiDelete size="20" color={colors.danger} />
                                </button>
                              </div>
                            ))}
                        </div>
                      </div>

                      {timeModal && (
                        <TimeModal
                          title="Available Time"
                          description="Set your available time for the date picked"
                          buttonText="Continue"
                          data={selectedRange}
                          onConfirm={handleDateTimeSubmit}
                          onClose={handleCloseTimeModal}
                          handleOnChange={handleTimeChange}
                          handleChange={setSelectedRange}
                        />
                      )}

                      {isModalOpen && (
                        <ConfirmationModal
                          title="Confirm Date and Time"
                          description="Are you sure you want to set the date and time as available?"
                          buttonText="Create"
                          onConfirm={handleConfirmCreateDate}
                          onClose={handleCloseModal}
                          data={selectedRange}
                          isLoading={isLoading}
                        />
                      )}

                      {isDeleteModalOpen && (
                        <DeleteModal
                          title="Confirm Delete Action"
                          description="Are you sure you want to delete the date from your record?"
                          buttonText="Delete"
                          onConfirm={handleConfirmDeleteDate}
                          onClose={handleDeleteModal}
                          isLoading={isLoading}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : activeTab === "pricing" ? (
              <div className="sm:mx-5">
                <h5 className="text-[15px] font-semibold">
                  Pricing
                  <span className="text-[10px] ml-1">(Doctors Only)</span> 
                </h5>
                <p className="text-[12px]">
                  Note 20% will be deducted from each consultation bookings.
                </p>

                <div className="my-2">
                  <div className="mt-2">
                    <label className="text-[10px] font-bold">
                      What's you minimum consultation time(mins)?
                    </label>
                    <select
                      name="duration"
                      value={consultationData.duration}
                      onChange={handleConsultationChange}
                      className="block w-full px-3 py-2 text-base placeholder-sm font-normal text-gray-700  bg-clip-padding border border-gray-300 rounded-md transition ease-in-out m-0 focus:ring-bgbutton duration-150"
                    >
                      <option value="">Select</option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="60">60</option>
                      <option value="120">120</option>
                    </select>
                  </div>

                  <div className="w-full">
                    <TextInput
                      label="Minimum Consultation Fee(₦):"
                      type="number"
                      id="consultation_amount"
                      name="consultation_amount"
                      value={consultationData.consultation_amount}
                      onChange={handleConsultationChange}
                      className="sm:w-full focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div className="flex items-end justify-end mt-4">
                    <button
                      onClick={handleUpdateConsultation}
                      className="button"
                    >
                      <div className="flex items-center space-x-3">
                        {isLoading && <LoaderIcon size="20" color="white" />}
                        <p>Update</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ) : activeTab === "referee" ? (
              <div className="sm:mx-5 sm:flex-1 w-full">
                <div className="mx-2 p-2">
                  <h5 className="text-[15px] font-semibold">Referee</h5>
                  <p className="text-[10px]">
                    Let us know who your referee is!
                  </p>
                </div>

                <div className="my-2 grid grid-cols-1 sm:grid-cols-2 sm:gap-4 p-2">
                  <div>
                    <TextInput
                      label="Enter name"
                      type="text"
                      id="ref_name"
                      name="ref_name"
                      value={refData.ref_name}
                      onChange={handleRefereeChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div>
                    <TextInput
                      label="Enter email"
                      type="email"
                      id="ref_email"
                      name="ref_email"
                      value={refData.ref_email}
                      onChange={handleRefereeChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div>
                    <TextInput
                      label="Enter phone number"
                      type="text"
                      id="ref_phone"
                      name="ref_phone"
                      value={refData.ref_phone}
                      onChange={handleRefereeChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div>
                    <TextInput
                      label="Referee Job Title & Work Address"
                      type="text"
                      id="referee_address"
                      name="referee_address"
                      value={refData.referee_address}
                      onChange={handleRefereeChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div className="flex items-end justify-end mt-4">
                    <button onClick={handleUpdateReferee} className="button">
                      <div className="flex items-center space-x-3">
                        {isLoading && <LoaderIcon size="20" color="white" />}
                        <p>Update</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ) : activeTab === "bank_details" ? (
              <div className="mx-5 flex-1 p-2">
                <div className="mx-3">
                  <h5 className="text-[15px] font-semibold">
                    Bank Account Details
                  </h5>
                  <p className="text-[10px]">
                    Set up your bank account information.
                  </p>
                </div>

                <div className="my-2 grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
                  <div className="w-full relative">
                    <label className="text-[10px] font-bold">
                      Account Type
                    </label>
                    <select
                      name="account_type"
                      value={bankData.account_type}
                      onChange={(e) => handleBankChange(e)}
                      className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
                    >
                      <option value="" disabled>
                        Select Type
                      </option>
                      <option value="savings">Savings</option>
                      <option value="current">Current</option>
                    </select>
                    <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
                      <img
                        src={appointmentDataIcons.dropdownIcon}
                        className="w-3 h-3"
                        alt=""
                      />
                    </div>
                  </div>

                  <div>
                    <TextInput
                      label="Bank Name"
                      type="text"
                      id="bank_name"
                      name="bank_name"
                      value={bankData.bank_name}
                      onChange={handleBankChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div>
                    <TextInput
                      label="Account Name"
                      type="text"
                      id="account_name"
                      name="account_name"
                      value={bankData.account_name}
                      onChange={handleBankChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div>
                    <TextInput
                      label="Account Number"
                      type="text"
                      id="account_number"
                      name="account_number"
                      value={bankData.account_number}
                      onChange={handleBankChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div className="flex items-end justify-end mt-4">
                    <button onClick={handleUpdateBank} className="button">
                      <div className="flex items-center space-x-3">
                        {isLoading && <LoaderIcon size="20" color="white" />}
                        <p>Update</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ) : activeTab === "next_of_kin" ? (
              <div className="mx-5 flex-1">
                <h5 className="text-[15px] font-semibold">
                  Next of Kin Details
                </h5>
                <p className="text-[10px]">
                  Let us know your next of kin information.
                </p>

                <div className="my-2 grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
                  <div className="w-full">
                    <TextInput
                      label="Next of Kin Full Name"
                      type="text"
                      id="next_of_kin_name"
                      name="next_of_kin_name"
                      value={kinData.next_of_kin_name}
                      onChange={handleKinChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div>
                    <TextInput
                      label="Relationship with Kext of Kin"
                      type="text"
                      id="next_of_kin_relationship"
                      name="next_of_kin_relationship"
                      value={kinData.next_of_kin_relationship}
                      onChange={handleKinChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div>
                    <TextInput
                      label="Next of Kin Phone Number"
                      type="text"
                      id="next_of_kin_mobile"
                      name="next_of_kin_mobile"
                      value={kinData.next_of_kin_mobile}
                      onChange={handleKinChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div>
                    <TextInput
                      label="Next of Kin Address"
                      type="text"
                      id="next_of_kin_address"
                      name="next_of_kin_address"
                      value={kinData.next_of_kin_address}
                      onChange={handleKinChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                    />
                  </div>

                  <div className="flex items-end justify-end mt-4">
                    <button onClick={handleUpdateKin} className="button">
                      <div className="flex items-center space-x-3">
                        {isLoading && <LoaderIcon size="20" color="white" />}
                        <p>Update</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthWorkerEditProfile;