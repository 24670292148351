import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  FaPaperPlane,
  FaTimes,
  FaEye,
  FaFile,
  FaPaperclip,
  FaCheck,
} from "react-icons/fa";
// import {  } from "react-icons/bs";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { io } from "socket.io-client";

import { useStateProvider } from "../../manager/StateContext";
import {
  getFileType,
  getFileUri,
  getDocumetExtension,
  getFileName,
} from "../../utils/file";

function ChatModal({ item = {}, closeModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [webSocketState, setWebSocketState] = useState(0);
  const [uploadMessage, setUploadMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [{ token, user_data }, dispatch] = useStateProvider();

  const webSocketClientRef = useRef();

  function parseJson(data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

  async function getMessages() {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${process.env.REACT_APP_API_LIVE_URL}/api/messages/fetch`;
      const response = await axios.post(
        url,
        JSON.stringify({
          convo_id: item.conversationId,
        }),
        config
      );
      setMessages(response.data.entity.messages);
    } catch (error) {
    } finally {
      setIsLoading(false);
      const lastMessageElement = document.getElementById("last-message");
      setTimeout(
        () => lastMessageElement?.scrollIntoView({ behavior: "smooth" }),
        500
      );
    }
  }

  // fetch iniital messages
  useEffect(() => {
    getMessages();
  }, []);

  // connect to websocket
  useEffect(() => {
    const webSocketClient = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}?channel=${item.conversationId}&id=${item.carerId}&carer_id=${item.carerId}`
    );
    // const webSocketClient = new WebSocket(`ws://192.168.26.85:8080?channel=${item.conversationId}&id=${item.carerId}&carer_id=${item.carerId}`);

    // set websocket client ref
    webSocketClientRef.current = webSocketClient;

    webSocketClientRef.current.onopen = function (event) {
      console.log("connected: ", event);
    };

    webSocketClientRef.current.onmessage = function (event) {
      const data = parseJson(event.data);
      if (data?.type === "message") {
        setMessages((initial) => [...initial, data]);
        setTimeout(() => {
          const lastMessageElement = document.getElementById("last-message");
          lastMessageElement?.scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    };

    webSocketClientRef.current.onerror = function (event) {
      // optionally close initial opened socket
      console.log("error event: ", event);
      webSocketClientRef.current?.close(
        3001,
        JSON.stringify({ channel: item?.conversationId, id: item?.carerId })
      );
      closeModal();
      if (webSocketState > 1)
        return toast.error(
          "An error occured keeping you connected to the meeting..."
        );
      toast.error("There was an error starting the meeting...");
    };

    webSocketClientRef.current.onclose = function (event) {
      if (event.code !== 3001) {
        // optionallly close initial opened socket
        webSocketClientRef.current?.close(
          3001,
          JSON.stringify({ channel: item?.conversationId, id: item?.carerId })
        );
        // console.log("reconnecting to websocket...");
        setWebSocketState((initial) => initial + 1);
      }
      // console.log("web socket closed reason: ", event.reason);
      // console.log("code: ",event.code)
    };

    return function () {
      if (webSocketClientRef.current.readyState === 1) {
        // console.log("closed websockek on modal unmounted")
        webSocketClientRef.current?.close(
          3001,
          JSON.stringify({ channel: item?.conversationId, id: item?.carerId })
        );
      }
    };
  }, [webSocketState]);

  async function saveSentMessage(message, convoId) {
    try {
      const data = await axios.post(
        process.env.REACT_APP_API_LIVE_URL + "/api/messages",
        JSON.stringify({ message, convo_id: convoId }),

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return { ...data, success: true };
    } catch (error) {
      return {
        status: error?.status,
        data: error?.data,
        success: false,
        error,
      };
    }
  }

  async function sendMessage(e) {
    e.preventDefault();
    if (!message) return;
    try {
      webSocketClientRef.current.send(
        JSON.stringify({
          message: message,
          sender_id: item.carerId,
          id: item.carerId,
          user_id: item.carerId,
          carer_id: item.carerId,
          patientId: item.patient._id,
          channel: item.conversationId,
          type: "message",
        })
      );
      setMessages([
        ...messages,
        { message: message, sender_id: item.carerId, user_id: item.carerId },
      ]);
      saveSentMessage(message, item.conversationId);
      setMessage("");
      setTimeout(() => {
        const lastMessageElement = document.getElementById("last-message");
        lastMessageElement?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    } catch (error) {
      console.log("error sending: ", error);
    }
  }

  const uploadFile = async (e) => {
    e.preventDefault();
    console.log("length of files selected: ", e.target?.files?.length);
    if (e.target?.files?.length === 0)
      return toast.error("Please select a file");
    let toastID = toast.success("Uploading file", { duration: 30000 });
    const formData = new FormData();
    formData.append("file", e.target?.files[0]);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_LIVE_URL}/api/account/upload-files`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200 && response.data?.status?.code === 100) {
        toast.remove(toastID);
        toast.success("File uploaded file", {
          icon: <FaCheck color="green" size={20} />,
        });
        const file = response.data?.entity?.files[0];
        webSocketClientRef.current.send(
          JSON.stringify({
            message: file,
            sender_id: item.carerId,
            id: item.carerId,
            user_id: item.carerId,
            carer_id: item.carerId,
            patientId: item.patient._id,
            channel: item.conversationId,
            type: "message",
          })
        );
        setMessages([
          ...messages,
          { message: file, sender_id: item.carerId, user_id: item.carerId },
        ]);
        saveSentMessage(file, item.conversationId);
        setTimeout(() => {
          const lastMessageElement = document.getElementById("last-message");
          lastMessageElement?.scrollIntoView({ behavior: "smooth" });
        }, 500);
        return;
      }
      throw response;
    } catch (error) {
      console.log("error: ", error);
      toast.remove(toastID);
      toast.error("File Upload failed");
    } finally {
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(e.target?.files[0]);
    console.log("selected file: ", e.target?.files[0]);
    // TODO: handle file upload, save to databse and send file uri over websocket...
    console.log("handle File Upload...");
  };
  return (
    <div className=" z-30 w-screen h-screen flex-col fixed top-0 left-0 flex justify-center items-center bg-white/[0.9] px-3 py-5 ">
      <div className="md:w-[500px] md:h-3/4 w-[90%] relative h-[60%] bg-slate-300 px-2 py-5 rounded-t-lg overflow-hidden box-border pr-0">
        {/* close button */}
        <div
          className=" absolute top-2 py-1 right-1 px-2 z-50 bg-red-600 rounded-2xl w-[62px] flex justify-center items-center cursor-pointer"
          onClick={() => {
            closeModal();
          }}
        >
          <span className="text-white font-bold text-[10px]">Close</span>
          <FaTimes size={10} color="white" />
        </div>
        {/* header */}
        <div className=" px-2 w-full py-3 bg-inherit font-bold left-0 bg-slate-100 top-0 text-sm text-center absolute mb-3">
          <div className="w-full flex justify-center items-center">
            {/* patient name */}
            <h2>{`${item.service_for}`}</h2>
          </div>
          {isLoading ? <Spinner text="loading previous messages" /> : null}
        </div>
        {/* messages */}
        {!isLoading && messages.length === 0 ? (
          <h2 className="font-thin text-lg">No messages</h2>
        ) : null}

        {/* list messages */}
        <div
          id="message-container"
          className="overflow-y-auto overflow-x-hidden scrool-thin h-full pt-10 px-2"
        >
          {messages.map((data, index) => {
            return data.user_id == item.carerId ? (
              <SentMessage message={data.message} key={index.toString()} />
            ) : (
              <ReceivedMessage message={data.message} key={index.toString()} />
            );
          })}
          <div id="last-message"></div>
        </div>
      </div>
      <form
        className="md:w-[500px] w-[90%] h-30 bg-slate-100  flex items-center justify-between bg-inherit rounded-b-lg overflow-hidden box-border px-3 py-2"
        onSubmit={(e) => sendMessage(e)}
      >
        <div className="flex items-center box-border overflow-hidden justify-between md:w-[90%] resize-none w-[80%] pr-1 bg-white rounded-lg">
          <input
            type="text"
            placeholder="Type a message"
            multiple={true}
            className="md:w-[90%] resize-none w-[80%] px-3 py-2 h-20  outline-none border-none"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="">
            <label
              htmlFor="file-upload"
              className="border-none outline-none cursor-pointer w-[40px] h-[40px] rounded-lg bg-[#47A7FD] flex items-center justify-center"
            >
              <FaPaperclip color="white" size={20} />
            </label>
            <input
              id="file-upload"
              type="file"
              multiple={false}
              onChange={(e) => uploadFile(e)}
              className="hidden"
            />
          </div>
        </div>
        <button
          onClick={(e) => sendMessage(e)}
          type="submit"
          className="h-[40px] w-[40px] rounded-full bg-[#47A7FD]  flex items-center justify-center"
        >
          <FaPaperPlane color="white" size={20} />
        </button>
      </form>
    </div>
  );
}

export default ChatModal;

const SentMessage = ({ message }) => {
  const fileType = getFileType(message);
  const uri = getFileUri(message);
  const [open, setOpen] = useState(false);

  if (fileType === "image") {
    return (
      <motion.div
        initial={{ opacity: 0, animationDuration: 0.5 }}
        animate={{ opacity: 1, animationDuration: 0.5 }}
        className="flex w-full justify-end mb-2"
      >
        <div className="bg-inherit text-gray-800 rounded-tl-sm rounded-2xl ">
          <img
            onClick={() => setOpen(true)}
            style={{ height: "300px", width: "200px", display: "inline-block" }}
            className="inline-block object-cover rounded-xl cursor-pointer"
            src={uri}
          />
        </div>
        {open ? (
          <DownloadView
            link={uri}
            close={() => setOpen(false)}
            fileName={message}
          />
        ) : null}
      </motion.div>
    );
  }

  if (fileType === "document" || fileType === "video") {
    return (
      <motion.div
        initial={{ opacity: 0, animationDuration: 0.5 }}
        animate={{ opacity: 1, animationDuration: 0.5 }}
        className="flex w-full justify-end mb-2"
      >
        <div className="bg-slate-100 max-w-[50%] text-gray-800 py-2 flex flex-col items-center justify-center rounded-br-sm  rounded-2xl ">
          <FaFile
            className="inline-block cursor-pointer"
            size={150}
            color="#00aaff"
            onClick={() => setOpen(true)}
          />
          <p className="text-xs text-slate-700 font-bold mt-1 uppercase">
            {getDocumetExtension(message)}
          </p>

          {open ? (
            <DownloadView
              link={uri}
              close={() => setOpen(false)}
              fileName={message}
            />
          ) : null}
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, animationDuration: 0.5 }}
      animate={{ opacity: 1, animationDuration: 0.5 }}
      className="flex w-full justify-end mb-2"
    >
      <p
        style={{ whiteSpace: "pre-wrap" }}
        className="bg-blue-500 max-w-[70%] text-white rounded-br-sm rounded-2xl px-4 py-2"
      >
        {message}
      </p>
    </motion.div>
  );
};

const ReceivedMessage = ({ message = "" }) => {
  const fileType = getFileType(message);
  const uri = getFileUri(message);
  const [open, setOpen] = useState(false);

  if (fileType === "image") {
    return (
      <motion.div
        initial={{ opacity: 0, animationDuration: 0.5 }}
        animate={{ opacity: 1, animationDuration: 0.5 }}
        className="flex justify-start mb-2 overflow-hidden box-border "
      >
        <div
          style={{ whiteSpace: "pre-wrap" }}
          className="bg-inherit text-gray-800 rounded-tl-sm rounded-2xl"
        >
          <img
            onClick={() => setOpen(true)}
            style={{ height: "300px", width: "200px", display: "inline-block" }}
            className="inline-block object-cover rounded-xl cursor-pointer"
            src={uri}
          />
        </div>
        {open ? (
          <DownloadView
            link={uri}
            close={() => setOpen(false)}
            fileName={message}
          />
        ) : null}
      </motion.div>
    );
  }

  if (fileType === "document" || fileType === "video") {
    return (
      <motion.div
        initial={{ opacity: 0, animationDuration: 0.5 }}
        animate={{ opacity: 1, animationDuration: 0.5 }}
        className="flex justify-start mb-2 overflow-hidden box-border bg-inherit "
      >
        <div
          style={{ whiteSpace: "pre-wrap" }}
          className="bg-gray-200 max-w-[70%] flex flex-col items-center justify-center text-gray-800 rounded-tl-sm rounded-2xl py-2 "
        >
          <FaFile
            className="inline-block cursor-pointer"
            size={150}
            color="darkgray"
            onClick={() => setOpen(true)}
          />
          <p className="text-xs font-bold mt-1 uppercase">
            {getDocumetExtension(message)}
          </p>

          {open ? (
            <DownloadView
              link={uri}
              close={() => setOpen(false)}
              fileName={message}
            />
          ) : null}
        </div>
        {open ? (
          <DownloadView
            link={uri}
            close={() => setOpen(false)}
            fileName={message}
          />
        ) : null}
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, animationDuration: 0.5 }}
      animate={{ opacity: 1, animationDuration: 0.5 }}
      className="flex justify-start mb-2"
    >
      <p
        style={{ whiteSpace: "pre-wrap" }}
        className="bg-gray-200 max-w-[70%] text-gray-800 rounded-tl-sm rounded-2xl px-4 py-2"
      >
        {message}
      </p>
    </motion.div>
  );
};

const Spinner = ({ text = "" }) => {
  return (
    <div className="flex justify-center items-center">
      <p className="text-xs font-bold mr-3">{text}</p>
      <div className="animate-spin rounded-full h-3 w-3 border-2 border-transparent border-t-black"></div>
    </div>
  );
};

const DownloadView = ({ link, fileName = "", close }) => {
  const name = getFileName(link);
  return (
    <div
      onClick={close}
      className="flex cursor-default items-center justify-center h-full w-full p-1 fixed top-0 bg-transparent left-0"
    >
      <div className="outline-none relative bg-slate-200 rounded-md p-2 border-none flex flex-col items-center justify-center md:h-1/3 sm:h-1/4 md:w-1/3 sm:w-[95vw]">
        <div
          className="absolute right-2 top-2 cursor-pointer p-2 bg-red rounded-full w-fit bg-red-700"
          onClick={close}
        >
          <FaTimes className="text-white text-[20px] " />
        </div>

        <p className="text-slate-700 text-center mb-5">
          View File
          <br />
          <span className="text-[10px]">{`${name}`}</span>
        </p>

        <a
          href={link}
          target="_blank"
          className="outline-none bg-slate-100 rounded-md p-2 border-none flex items-center "
        >
          <span className="inline-block text-[14px] mx-2 font-bold">View</span>
          <FaEye className="text-slate-700 text-[20px]" />
        </a>
      </div>
    </div>
  );
};
