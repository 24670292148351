import React, { useState, useEffect } from "react";

import AdminHeader from "../../components/Admin/dashboard/AdminHeader";
import AdminLandingBlock from "../../components/Admin/dashboard/AdminLandingBlock";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import CorporateSidebar from "../../components/corporatedashboard/CorporateSidebar";
import CardsContainer from "../../containers/corporateContainer/CardsContainer";
import { useAuthManager } from "../../manager/useAuthManager";
import axios from "axios";
import { toast } from "react-toastify";
import { FaUser, FaUserCheck, FaStethoscope, FaUserNurse, FaArrowUp, FaArrowDown } from "react-icons/fa";

import { phrData } from "../../services/data/phrData";

export const delQuery = (asPath) => {
  return asPath.split("?")[ 0 ];
};

const AdminStatistics = () => {
  const [ isOpen, setOpen ] = useState(false);
  const [ stats, setStats ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ showActiveWeekUsers, setShowActiveWeekUsers ] = useState(true);

  const fetchAdminStatistics = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/stats`);
      setStats(response.data.data);
    } catch (error) {
      toast.error("There was an error fetching your statistics! Please try again.");
    } finally {
      setLoading(false);
    }

  }


  const loginAdmin = () => {
    // TODO login admin before showing stats

  }


  useEffect(() => {
    fetchAdminStatistics();
  }, [])

  return (
    <div className=" font-Montserrat min-h-[120vh] md:float-right sm:clear-both sm:float-none ">
      {loading ? <Spinner /> : null}
      <CorporateSidebar isOpen={isOpen} setOpen={setOpen} />
      <AdminHeader isOpen={isOpen} setOpen={setOpen} />
      {loading && false ? null :
        <div className="w-full min-h-screen px-[36px] pt-[22.5px] ">
          {/* statistics container */}
          <div className="w-full h-full px-3 mb-10">
            <p className="lg:text-[24px] md:text[24px] font-semibold mb-5 mt-10 text-left sm:text-[16px]">General Overview</p>
            <div className="lg:flex md:flex w-full flex-wrap grid grid-cols-2  ">
              {/* total users count */}
              <div className="lg:w-1/6 mb-5 md:w-1/3 w-2/5 flex flex-col items-center justify-between">
                <p className="font-bold text-2xl flex items-center "> {stats.totalUsersCount || 0} <FaUser size={12} className="inline-block ml-1" /> </p>
                <p className="text-[10px] text-gray-600 text-center px-1">Total Users  </p>
              </div>
              {/* veried users count */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold text-2xl"> {stats.verifiedUsersCount || 0} <span className="h-3 w-3 rounded-full bg-green-500 inline-block ml-1"></span>  </p>
                <p className="text-[10px] text-gray-600 text-center px-1">Verified Users</p>
              </div>
              {/* unverified users count */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold text-2xl"> {stats.unverifiedUsersCount || 0} <span className="h-3 w-3 rounded-full bg-red-500 inline-block ml-1"></span>  </p>
                <p className="text-[10px] text-gray-600 text-center px-1">Unverified Users</p>
              </div>
              {/* health workers count */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold flex items-center text-2xl"> {stats.healthWorkersCount || 0} <FaUserNurse size={12} className="inline-block ml-1" /> </p>
                <p className="text-[10px] text-gray-600 text-center px-1"> Total Health Workers </p>
              </div>
              {/* health workers verified */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold text-2xl"> {stats.verifiedHealthWorkersCount || 0} <span className="h-3 w-3 rounded-full bg-green-500 inline-block ml-1"></span>  </p>
                <p className="text-[10px] text-gray-600 text-center px-1">Verified Health Workers</p>
              </div>
              {/* unverified health workers */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold text-2xl"> {stats.unverifiedHealthWorkersCount || 0} <span className="h-3 w-3 rounded-full bg-red-500 inline-block ml-1"></span>  </p>
                <p className="text-[10px] text-gray-600 text-center px-1">Unverified Health Workers</p>
              </div>

            </div>
            {/* onboarding overview */}
            <p className="lg:text-[24px] md:text[24px] font-semibold mb-5 mt-10 text-left sm:text-[16px]">New Users Overview</p>
            <div className="lg:flex md:flex w-full flex-wrap grid grid-cols-2 ">
              {/* total new users from last 30 days */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold flex items-center text-2xl">
                  {stats.lastMonthNewUsersCount || 0}
                  <FaUser size={12} className="inline-block ml-1" />
                  <PercentageIndicator className="mx-1" percentage={stats.lastMonthNewUsersPercentageIncrease?.toFixed(2)} />
                </p>
                <p className="text-[10px] text-gray-600 text-center px-1">Total New Users From The Past 30 Days</p>
              </div>
              {/* total new users from last 7 days */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold flex items-center text-2xl"> {stats.lastWeekNewUsersCount || 0} <FaUser size={12} className="inline-block ml-1" /> </p>
                <p className="text-[10px] text-gray-600 text-center px-1">Total New Users From The Past 7 Days</p>
              </div>
              {/* new health workers from past 30 days */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold flex items-center text-2xl">
                  {stats.lastMonthNewHealthWorkersCount || 0}
                  <FaUserNurse size={12} className="inline-block ml-1" />
                  <PercentageIndicator className="mx-1" percentage={stats.lastMonthNewHealthWorkersPercentageIncrease?.toFixed(2)} />
                </p>
                <p className="text-[10px] text-gray-600 text-center px-1">New Health Workers From The Past 30 Days</p>
              </div>
              {/* new health workers from the past 7 days */}
              <div className="lg:w-1/6 md:w-1/3 w-2/5 mb-5 flex flex-col items-center justify-between">
                <p className="font-bold flex items-center text-2xl"> {stats.lastWeekNewHealthWorkersCount || 0} <FaUserNurse size={12} className="inline-block ml-1" /> </p>
                <p className="text-[10px] text-gray-600 text-center px-1">New Health Workers From The Past 7 Days</p>
              </div>
            </div>

            {/* <p className="lg:text-[24px] md:text[24px] font-semibold mb-5 mt-10 text-left sm:text-[16px]">Most Active Health Workers from the Past 30 Days</p> */}
            <div className="w-full mt-10 grid relative grid-cols-1 sm:grid-cols-2 border-t-2 border-t-gray-300">

              {/* most recent 4 helath workers sign up */}
              <div className="w-full sm:w-1/2 " >
                <p className="lg:text-[18px] md:text[16px] font-semibold mb-5 mt-10 text-left sm:text-[14px]">
                  Recently Signed up <br /> Health Workers
                </p>
                {
                  stats?.mostRecentSignedUpHealthWorkers?.map((item, index) => (
                    <a
                      className="bg-transparent border-none no-underline"
                      href={item.carer_id ? `https://stageadmin.mymedicalbank.com/carer/${item.carer_id}` : "#"}
                      target={item.carer_id ? "_blank" : ""}
                    >
                      <HealthWorkerUserList item={item} key={index.toString()} />
                    </a>
                  ))
                }
              </div>

              {/* most recent 4 user sign up */}
              <div className=" w-full sm:w-1/2 " >
                <p className="lg:text-[18px] md:text[16px] font-semibold mb-5 mt-10 text-left sm:text-[14px]">
                  Recently Signed up <br /> Users
                </p>
                {
                  stats?.mostRecentSignedUpUsers?.map((item, index) => (
                    <a
                      className="bg-transparent border-none no-underline"
                      href={item.id ? `https://stageadmin.mymedicalbank.com/patient/${item.id}` : "#"}
                      target={item.id ? "_blank" : ""}
                    >
                      <HealthWorkerUserList item={item} key={index.toString()} />
                    </a>
                  ))
                }
              </div>

            </div>

            {/* active users section */}
            <div className="w-full flex justify-between items-start pt-5 mt-10 mb-5 border-t-2 border-t-gray-300">
              <p className="lg:text-[18px] sm:text-[16px] font-semibold text-left text-[12px]">
                {
                  showActiveWeekUsers ? "Most Active Users from the Past 7 Days" : "Most Active Users from the Past 30 Days"
                }
              </p>

              <div className="ml-1">
                <div className="flex mb-3 items-start w-32 justify-between">
                  <p className=" text-[10px]"> 7 Days Ago</p>
                  <div
                    onClick={() => setShowActiveWeekUsers(true)}
                    className="h-3 ml-3 w-3 border-2 border-blue-500 p-[1px] rounded-full flex items-center justify-center"
                  >
                    <div className={`h-2 w-2 ${showActiveWeekUsers && "bg-blue-500"} rounded-full`} />
                  </div>
                </div>

                <div className="flex items-start w-32 justify-between ">
                  <p className="text-[10px]"> 30 Days Ago</p>
                  <div
                    onClick={() => setShowActiveWeekUsers(false)}
                    className="h-3 w-3 ml-3 border-2 border-blue-500 p-[1px] rounded-full flex items-center justify-center"
                  >
                    <div className={`h-2 w-2 ${!showActiveWeekUsers && "bg-blue-500"} rounded-full`} />
                  </div>
                </div>

              </div>
            </div>
            <div className="w-full grid relative grid-cols-1 sm:grid-cols-2 ">


              {/* most active helath workers week/month */}
              {
                showActiveWeekUsers ?
                  <div className="w-full sm:w-1/2 " >
                    <p className="lg:text-[18px] md:text[16px] font-semibold mb-5 mt-10 text-left sm:text-[14px]">
                      Most Active <br /> Health Workers
                    </p>
                    {
                      stats?.lastWeekActiveHealthworkers?.data?.map((item, index) =>
                      (
                        <a
                          className="bg-transparent border-none no-underline"
                          href={item.carer_id ? `https://stageadmin.mymedicalbank.com/carer/${item.carer_id}` : "#"}
                          target={item.carer_id ? "_blank" : ""}
                        >
                          <ActiveUsers item={item} key={index.toString()} />
                        </a>
                      ))
                    }
                  </div>
                  :
                  <div className="w-full sm:w-1/2 " >
                    <p className="lg:text-[18px] md:text[16px] font-semibold mb-5 mt-10 text-left sm:text-[14px]">
                      Most Active <br /> Health Workers
                    </p>
                    {
                      stats?.lastMonthActiveHealthworkers?.data?.map((item, index) =>
                      (
                        <a
                          className="bg-transparent border-none no-underline"
                          href={item.carer_id ? `https://stageadmin.mymedicalbank.com/carer/${item.carer_id}` : "#"}
                          target={item.carer_id ? "_blank" : ""}
                        >
                          <ActiveUsers item={item} key={index.toString()} />
                        </a>
                      ))
                    }
                  </div>
              }

              {/* most active user week/month */}
              {
                showActiveWeekUsers ?
                  <div className=" w-full sm:w-1/2 " >
                    <p className="lg:text-[18px] md:text[16px] font-semibold mb-5 mt-10 text-left sm:text-[14px]">
                      Most Active <br className="sm:display-none" /> Users
                    </p>
                    {

                      stats?.lastWeekActiveUsers?.data?.map((item, index) =>
                      (
                        <a
                          className="bg-transparent border-none no-underline"
                          href={item.id ? `https://stageadmin.mymedicalbank.com/patient/${item.id}` : "#"}
                          target={item.id ? "_blank" : ""}
                        >
                          <ActiveUsers item={item} key={index.toString()} />
                        </a>
                      ))
                    }
                  </div>
                  :
                  <div className=" w-full sm:w-1/2 " >
                    <p className="lg:text-[18px] md:text[16px] font-semibold mb-5 mt-10 text-left sm:text-[14px]">
                      Most Active <br className="sm:display-none" /> Users
                    </p>
                    {
                      stats?.lastMonthActiveUsers?.data?.map((item, index) =>
                      (
                        <a
                          className="bg-transparent border-none no-underline"
                          href={item.id ? `https://stageadmin.mymedicalbank.com/patient/${item.id}` : "#"}
                          target={item.id ? "_blank" : ""}
                        >
                          <ActiveUsers item={item} key={index.toString()} />
                        </a>
                      ))
                    }
                  </div>

              }

            </div>

          </div>
          {/* <CardsContainer />
        <div className="flex flex-wrap w-[100%] gap-3 mt-[70px]">
          {phrData &&
            phrData.map((card) => (
              <div key={card.label}>
                <a href={card.to} className="w-[100%]">
                  <img src={card.img} alt="" className="w-[350px]" />
                </a>
              </div>
            ))}
          {[1, 2, 3].map((card) => (
            <AdminLandingBlock key={card} />
          ))}
        </div> */}
        </div>
      }
    </div>
  );
};

const PercentageIndicator = ({ percentage, className, size = 7 }) => {
  return (
    <div className={`inline-block ${className}`}>
      <div className={`flex items-center `}>
        <div className="flex flex-col items-center" >
          <FaArrowUp size={size} color={percentage > 0 && percentage !== null ? "green" : "gray"} />
          <FaArrowDown size={size} color={percentage < 0 && percentage !== null ? "red" : "gray"} />
        </div>
        <p className="text-[12px] font-thin text-black">
          {percentage > 0 && percentage !== null ? `${percentage}%` : ""}
          {percentage < 0 && percentage !== null ? `${percentage}%` : ""}
          {percentage == null || percentage == 0 ? "0%" : ""}
        </p>
      </div>
    </div>
  );
}


const Spinner = () => {
  return (
    <div className="fixed pt-3 flex top-0 w-screen left-0 bg-transparent z-50 items-center justify-center  ">
      <div className="animate-spin border-transparent rounded-full border-2 h-5 w-5 border-x-black" />
    </div>
  );
};
const HealthWorkerUserList = ({ item }) => {
  return (
    <div className="flex items-center justify-between mb-3 w-full pb-1 border-b-gray-300 border-b-2">
      <img
        className="h-10 w-10 inline-block object-contain rounded-full bg-gray-500"
        src={`https://stage.mymedicalbank.com/uploads/${item.imageUrl}`} />

      <p className="lg:text-[12px] w-[80%] md:text[12px] sm:text[14px] text-[12px] text-right">{item.first_name} {item.last_name}
        <p className="lg:text-[14px] md:text[12px] sm:text[14px]">{item.email}</p>
      </p>
    </div>
  )
}

const ActiveUsers = ({ item }) => {
  return (
    <div className="flex items-center justify-between mb-3 w-full pb-1 border-b-gray-300 border-b-2">
      <img
        className="h-10 w-10 inline-block object-contain rounded-full bg-gray-500"
        src={`https://stage.mymedicalbank.com/uploads/${item.imageUrl}`} />

      <p className="lg:text-[12px] md:text[12px] sm:text[12px] text-[12px] text-right">
        {item.first_name?.trim()} {item.last_name?.trim()}
        <p className="inline-block ml-1 ">{item.email?.trim()}</p>
        <p className="inline-block ml-1 ">({item.activity.activityCount} Activities)</p>
      </p>
    </div>
  )
}

export default AdminStatistics;


/*



*/