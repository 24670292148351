import React, { useEffect, useState } from "react";

import AcountInfo from "../../components/corporatedashboard/AcountInfo";
import AppointmentTable from "../../components/corporatedashboard/AppointmentTable";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import Schedule from "../../components/corporatedashboard/Schedule";
import HealthWorkerDashboardSidebar from "../../components/global/HealthWorkerSidebar";
import HealthWorkerID from "../../components/Users/HealthWorkersUsers/healthWorker/HealthWorkerID";
import ScheduleContainer from "../../containers/corporateContainer/ScheduleContainer";
import ActivitiesHealthContainer from "../../containers/healthWorkersContainer/ActivitiesHealthContainer";
import BreakdownContainer from "../../containers/healthWorkersContainer/BreakdownContainer";
import PaymentHealthContainer from "../../containers/paymentHealthContainer/PaymentHealthContainer";
import { useStateProvider } from "../../manager/StateContext";
import { useAuthManager } from "../../manager/useAuthManager";
import withAuth from "../../manager/withAuth";

import { transformBackendData } from "../../utils/helpers";

export const delQuery = (asPath) => {
  return asPath.split("?")[0];
};

const HealthWorkerDashboard = () => {
  const [isOpen, setOpen] = useState(false);
  const [calendarValue, setCalendarValue] = useState([]);
  const { fetchAppointments } = useAuthManager({
    middleware: "auth",
    role: "carer",
    redirectIfAuthenticated: "",
  });

  const [{ user_special_availability, user_appointments, health_worker_detail }, dispatch] =
    useStateProvider();

    // console.log(JSON.stringify(user_appointments, null, 2));

  useEffect(() => {
    if (user_special_availability && user_special_availability?.length > 0) {
      const transformedData = transformBackendData(user_special_availability);
      setCalendarValue(transformedData);
    }
  }, [user_special_availability]);

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if(user_appointments?.length > 0){
      const currentDate = new Date().toISOString().split("T")[0];
      const filtered = user_appointments?.filter(
        (item) => item.date === currentDate
      );
      setFilteredData(filtered);
    }

  }, [user_appointments]);

  useEffect(() => {
    fetchAppointments();
  }, [])

  return (
    <div className="flex sm:justify-between bg-[#fafafa]  font-Montserrat">
      <HealthWorkerDashboardSidebar isOpen={isOpen} setOpen={setOpen} />

      <div className="sm:w-screen sm:space-x-2">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />

        <div className="px-8">
        {
        health_worker_detail?.verify_status === 0 ? (
          <div className="flex justify-center w-full">
            <div className="flex mt-2 items-center w-fit justify-center py-1 px-2  rounded-3xl text-[10px] text-yellow-700 font-bold mr-1 bg-yellow-100">
              <span className="inline-block mr-[2px]" >Your account is currently going through verification</span>
            </div>
          </div>


        ) : null
      }
          <div className="mt-4 block sm:flex sm:space-x-10 h-screen overflow-y-auto custom-scrollbar sm:p-2 rounded-lg">
          <div className="block">
              <HealthWorkerID />
              <PaymentHealthContainer />
            </div>

            <div className="block">
              <div>
                <ScheduleContainer
                  calendarValue={calendarValue}
                  appointments={filteredData}
                />
              </div>
              <div className="mt-4">
                <Schedule data={filteredData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(HealthWorkerDashboard);
