// try {
//   const response = await axios.post(`${OLD_API_URL}api/account/upload-files`,
//     formdata,
//     {
//       headers: {
//         "Authorization": `Bearer ${config.token}`,
//         "Content-Type": "multipart/form-data",
//       },
//     }

//   )
//   if (response.status === 200 && response.data?.status?.code === 100) {
//     return { success: true, data: response.data?.entity?.files[ 0 ] };
//   }
//   throw response;

// } catch (error) {
//   return { success: false, data: error?.response };
//   // console.log("error uploading file: ", error.response?.data);
//   // setUploadMessage("Error uploading file");
//   // setMessages((initial) => {
//   //   let data = parseJson(JSON.stringify(initial));
//   //   data?.pop();
//   //   return data || [];
//   // })
// }
// // try {
// //   const data = await axios.get(
// //     API_URL + "api/v1/cooperate/availablility/special/" + id,
// //     {
// //       headers: {
// //         "Content-Type": "application/json",
// //         "Authorization": "Bearer " + this.telemedicineToken
// //       },
// //     }
// //   )

// //   return { ...data, success: true };

// // } catch (error) {
// //   return { status: error?.respone?.status, data: error?.response?.data, success: false, error: error.response };
// // }

// }

// supported image file types
export const imageFileTypesExtensions = ["jpg", "jpeg", "png", "gif", "webp"];

// supported video file types
export const videoFileTypesExtensions = [
  "mp4",
  "mkv",
  "mov",
  "avi",
  "webm",
  "wmv",
];

// supported document file types
export const documentsFileTypesExtensions = [
  "doc",
  "docx",
  "pdf",
  "ppt",
  "pptx",
  "xls",
  "xlsx",
  "txt",
  "csv",
];

export const fileSupported = (file = "") => {
  return [
    ...imageFileTypesExtensions,
    ...videoFileTypesExtensions,
    ...documentsFileTypesExtensions,
  ].includes(file?.split(".")?.pop()?.trim()?.toLocaleLowerCase());
};

export const getFileType = (file = "") => {
  if (
    imageFileTypesExtensions.includes(
      file?.split(".")?.pop()?.trim()?.toLocaleLowerCase()
    )
  )
    return "image";
  else if (
    videoFileTypesExtensions.includes(
      file?.split(".")?.pop()?.trim()?.toLocaleLowerCase()
    )
  )
    return "video";
  else if (
    documentsFileTypesExtensions.includes(
      file?.split(".")?.pop()?.trim()?.toLocaleLowerCase()
    )
  )
    return "document";
  else return false;
};

export const isFromSytemFile = (file = "") => {
  const fromSystemFile = file?.includes("c:/") || file?.includes("content://");
  return fromSystemFile;
};

export const getFileUri = (file = "") => {
  if (isFromSytemFile(file) === false) {
    const uri = `${process.env.REACT_APP_API_LIVE_URL}${file}`;
    return uri;
  } else return file;
};

export const getDocumetExtension = (file = "") => {
  const extensionName = file?.split(".")?.pop();
  return extensionName;
};

export const getFileName = (file = "") => {
  const fileName = file?.split("/")?.pop();
  return fileName || file;
};

export const isLoaclFile = (file = "") => {
  if (isFromSytemFile(file) === true) return true;
  else return false;
};
