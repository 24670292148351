import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaArrowAltCircleLeft } from "react-icons/fa";

import Availability from "../../components/setup/Availability";
import BankDetails from "../../components/setup/BankDetails";
import KinDetails from "../../components/setup/KinDetails";
import Pricing from "../../components/setup/Pricing";
import Profile from "../../components/setup/Profile";
import Qualification from "../../components/setup/Qualification";
import Referee from "../../components/setup/Referee";
import UserProfileHeader from "../../components/Users/ProfileSetup/UserProfileHeader";
import { useAuthManager } from "../../manager/useAuthManager";

import { sendError, setPrevTab, useQuery } from "../../utils/helpers";
import { colors } from "../../utils/style";

const HealthWorkerProfile = () => {

   const { fetchAppointments } = useAuthManager({
     middleware: "auth",
     role: "carer",
     redirectIfAuthenticated: "",
   });

   const [activeTab, setActiveTab] = useState("profile");
   // const query = useQuery();
   const [isLoading, setIsLoading] = useState(false);
   const [organizations, setOrganizations] = useState([]);
     const [specialities, setSpecialities] = useState([]);


   const tab = [
     {
       name: "Profile",
       key: "profile",
       description: "Profile Information.",
     },
     {
       name: "Verification Document",
       key: "qualification",
       description: "Update your document.",
     },
     {
       name: "Availability",
       subName:"(Doctors Only)",
       key: "appointment_setup",
       description: "Date and time setting.",
      },
      {
        name: "Pricing",
        subName:"(Doctors Only)",
        key: "pricing",
        description: "Setup your appointment pricing.",
     },
     {
       name: "Referee",
       key: "referee",
       description: "Who would you like to be your referee.",
     },
     {
       name: "Bank Details",
       key: "bank_details",
       description: "Payment acccount details setup",
     },
     {
       name: "Next of Kin",
       key: "next_of_kin",
       description: "Help us know your next of kin details",
     },
   ];

    const handleTabChange = (data) => {
      setActiveTab(data.key);
    };

      const fetchOrganizations = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          axios
            .get(
              `${process.env.REACT_APP_API_LIVE2_URL}/api/v1/cooperate/organisation/get-all-organisation`,
              config
            )
            .then((response) => {
              const { data } = response;
              setOrganizations(data.data.organisations);
            })
            .catch((error) => {
              const message = sendError(error);
              console.log(message);
            });
        } catch (error) {
          const message = sendError(error);
          console.log(message);
        }
      };

      const fetchSpecialties = async () => {
        try {
          axios
            .get(`${process.env.REACT_APP_API_LIVE_URL}/doctors-specialties`)
            .then((response) => {
              const { data } = response;
              setSpecialities(data.status.desc.specialties);
            })
            .catch((error) => {
              const message = sendError(error);
              console.log(message);
            });
        } catch (error) {
          const message = sendError(error);
          console.log(message);
        }
      };

     useEffect(() => {
       fetchOrganizations();
       fetchSpecialties();
     }, []);

    // useEffect(() => {
    //   const queryTab = query.get("tab");
    //   setActiveTab(queryTab);
    // }, [query]);

    const renderSteps = () => {
      switch (activeTab) {
        case "profile":
          return (
            <Profile
              activeTab={activeTab}
              tab={tab}
              setActiveTab={setActiveTab}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              organizations={organizations}
              specialities={specialities}
              handlePrev={handlePrev}
            />
          );
        case "qualification":
          return (
            <Qualification
              activeTab={activeTab}
              tab={tab}
              setActiveTab={setActiveTab}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handlePrev={handlePrev}
            />
          );
        case "appointment_setup":
          return (
            <Availability
              activeTab={activeTab}
              tab={tab}
              setActiveTab={setActiveTab}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handlePrev={handlePrev}
            />
          );
        case "pricing":
          return (
            <Pricing
              activeTab={activeTab}
              tab={tab}
              setActiveTab={setActiveTab}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handlePrev={handlePrev}
            />
          );
        case "referee":
          return (
            <Referee
              activeTab={activeTab}
              tab={tab}
              setActiveTab={setActiveTab}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handlePrev={handlePrev}
            />
          );
        case "bank_details":
          return (
            <BankDetails
              activeTab={activeTab}
              tab={tab}
              setActiveTab={setActiveTab}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handlePrev={handlePrev}
            />
          );
        case "next_of_kin":
          return (
            <KinDetails
              activeTab={activeTab}
              tab={tab}
              setActiveTab={setActiveTab}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handlePrev={handlePrev}
            />
          );
        default:
          return null;
      }
    }

      const handlePrev = () => {
        setPrevTab(activeTab, tab, setActiveTab);
      };
   
  return (
    <div>
      <UserProfileHeader Breadcrumb="Provider's Setup Page" />
      <div className="bg-[#F7F8FA]">
        <div className="block sm:flex">
          <div className="sm:py-6 bg-white rounded-[20px] p-2 flex items-center justify-between">
            <div>
              <ol className="relative  text-gray-500 sm:border-l sm:y-8 mx-8  border-gray-200 dark:border-gray-700 dark:text-gray-400 ">
                {tab?.map((item, index) => (
                  <li
                    className={`${
                      item.key === activeTab ? "block" : "hidden sm:block"
                    } sm:mb-10  ml-8 sm:ml-12 cursor-pointer`}
                    onClick={() => handleTabChange(item)}
                  >
                    <span
                      class={`absolute  text-white font-bold font-Roboto flex items-center justify-center w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] ${
                        activeTab === item.key
                          ? "bg-[#47A7FD] "
                          : "bg-[#C9E5FF]"
                      }
                rounded-xl -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900`}
                    >
                      {index + 1}
                    </span>
                    <h3 className="font-bold text-[15.5px] leading-tight text-[#303030] ">
                      {item.name}
                    </h3>
                    <p className="text-[10px] text-start text-[#303030] max-w-xs font-Poppins">
                      {item.description}
                    </p>
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <div className="sm:ml-4 mt-4 sm:mt-0 px-3 py-6 bg-white rounded-[20px] w-full h-screen overflow-y-auto custom-scrollbar">
            {renderSteps()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthWorkerProfile;
